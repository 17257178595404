<template>
  <div>
    <el-card style="width: 30%" >
          <template #header>
            <span style="font-size: small">{{$t('msg.route.paramEdit')}}</span>
            <el-button type="primary" size="small" style="float: right" @click="addRecord()">{{ $t('msg.basicInfo.addRecord') }}</el-button>
          </template>
          <el-table :data="stringList">
            <el-table-column prop="legcode" label="String" sortable>
              <template #default="scope">
                <el-input v-model="stringList[scope.$index]" size="small" v-if="scope.$index+editFlag===-1"></el-input>
                <span v-else>{{scope.row}}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" :label="$t('msg.rate.Operations')" width="200px" align="center">
              <template #default="scope">
                <el-button type="success" size="small" @click="confirmEdit(scope.$index)" plain v-if="scope.$index+editFlag===-1">Confirm</el-button>
                <el-button type="danger" size="small" @click="cancelEdit()" plain v-if="scope.$index+editFlag===-1">Cancel</el-button>
                <el-button type="primary" size="small" @click="startEdit(scope.$index)" v-if="scope.$index+editFlag!==-1">Edit</el-button>
                <el-button type="danger" size="small" @click="delRecord(scope.$index)" v-if="scope.$index+editFlag!==-1">Del</el-button>
              </template>
            </el-table-column>
          </el-table>
    </el-card>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'
export default defineComponent({
  name: 'paramEdit',
  props: {
    dataSourceTag: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const editFlag = ref(0)
    const stringList = ref([])
    const oldString = ref('')
    const dataTag = 'table=node001.z_retool_md_charge' + props.dataSourceTag + '&col=charge' + props.dataSourceTag
    const initPage = () => {
      const reqURL = process.env.VUE_APP_BASE + '/charCode/getSingleCol'
      axios.post(reqURL, dataTag).then((response) => {
        stringList.value = response.data
        console.log(stringList.value)
      }).catch((response) => {
        console.log(response)
      })
    }
    const addRecord = () => {
      if (editFlag.value < 0) {
        alert('Please click comfirm or cancel to close opened input first!')
        return false
      }
      stringList.value.push('')
      editFlag.value = -(stringList.value.length)
    }
    const recordSubmit = (id) => {
      const param = dataTag + '&newParam=' + stringList.value[id]
      const reqURL = process.env.VUE_APP_BASE + '/charCode/insertSingleCol'
      axios.post(reqURL, param).then((response) => {
        stringList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const startEdit = id => {
      if (editFlag.value < 0) {
        alert('Please click comfirm or cancel to close opened input first!')
        return false
      }
      oldString.value = stringList.value[id]
      editFlag.value = -(id + 1)
    }
    const cancelEdit = () => {
      editFlag.value = 0
      initPage()
    }
    const confirmEdit = (id) => {
      editFlag.value = 0
      if (oldString.value === '') {
        recordSubmit(id)
      } else {
        const param = dataTag + '&newParam=' + stringList.value[id] + '&oldParam=' + oldString.value
        const reqURL = process.env.VUE_APP_BASE + '/charCode/updateSingleCol'
        axios.post(reqURL, param).then((response) => {
          stringList.value = response.data
          oldString.value = ''
        }).catch((response) => {
          console.log(response)
        })
      }
    }
    const delRecord = id => {
      const param = dataTag + '&oldParam=' + stringList.value[id]
      const reqURL = process.env.VUE_APP_BASE + '/charCode/deleteByCol'
      axios.post(reqURL, param).then((response) => {
        stringList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    onMounted(() => {
      console.log(props.dataSourceTag)
      initPage()
    })
    return { editFlag, stringList, oldString, initPage, addRecord, recordSubmit, cancelEdit, confirmEdit, startEdit, delRecord }
  }
})
</script>
